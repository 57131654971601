import React, { useRef, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import gsap from 'gsap';
import TransitionLink from 'gatsby-plugin-transition-link';
import {
	isMobile,
	isWebpSupported,
	isAvifSupported,
	getLanguageSlug,
	testNetworkConnection,
	isTouchDevice,
} from '../utils/helpers';
import Layout from './../components/layout';
import Meta from './../components/meta';
import CursorMask from './../components/cursor-mask';
import SequenceCanvas from './../components/sequence-canvas';
import { PortalBlock } from './../components/portal';
import {
	ProgressBarBlock,
	ProgressBarContainer,
	ProgressBarLogoViewbox,
	ProgressBarClipPath,
	ProgressBarLogoPath,
} from './../components/progress-bar';
import {
	LoadingOverlayBlock,
	LoadingOverlayContainer,
	LoadingOverlayDetailText,
	LoadingOverlayTitle,
	LoadingOverlayDescription,
	LoadingOverlayImage,
} from './../components/loading-overlay';
import {
	SequenceSectionBlock,
	SequenceSectionTitleGroup,
	SequenceSectionSubtitle,
	SequenceSectionTitle,
	SequenceSectionDescription,
} from './../components/sequence-section';
import {
	DetailsArticleBlock,
	DetailsArticleText,
	DetailsArticleInfoWrapper,
	DetailsArticleLink,
	DetailsArticleLinkIcon,
} from './../components/details-article';
import {
	BuildingTransitionBlock,
	BuildingTransitionLabel,
	BuildingTransitionLink,
	BuildingTransitionIcon,
	BuildingTransitionFlexbox,
	BuildingTransitionIndexDot,
	BuildingTransitionText,
	BuildingTransitionTitle,
	BuildingTransitionWrapper,
	BuildingTransitionSvg,
	BuildingTransitionSvgGroup,
	BuildingTransitionIconPath,
	BuildingTransitionIconTextGroup,
	BuildingTransitionIconTextSpan,
} from './../components/building-transition';
import {
	VideoBlock,
	VideoFrame,
	VideoSource,
} from './../components/video';
import {
	FooterBlock,
	FooterContent,
	FooterLink,
} from './../components/footer';
import externalLinkIcon from './../images/red-arrow-right-bottom.svg';
import downloadIcon from './../images/download.svg';
import tapButtonIcon from './../images/red-arrow-horizontal.svg';
import shareImageSoMe from './../images/shareimage_bagfacaden.png';
import GlobalContext from '../context/global-context';

const BuildingTemplate = ({ pageContext, data, entry }) => {
	const { state } = entry;
	const [ languages ] = data.locales.edges;
	const { edges: fullwidthImages } = data.fullwidthImages;
	const { edges: loadingImages } = data.loadingImages;
	const { buildingIndex, allPages, videos, sequence, assetsFolderUri, details, loadingScreen, footer } = pageContext;
	const { t, i18n } = useTranslation();
	const introDescription = useRef();
	const buildingTitleRef = useRef();
	const buildingDetailsTextRef = useRef();
	const navigationControlsRef = useRef();
	const sequenceCanvasBlockRef = useRef();
	const footerTransitionRef = useRef();
	const footerCursorShape = useRef();
	const productsArticleRef = useRef();
	const [loadingCompleted, setLoadingCompleted] = useState(false);
	const [showDoubleArrows, setShowDoubleArrows] = useState(false);
	const [activateFullscreenMasking, setActivateFullscreenMasking] = useState(false);
	const [isVideosPlayable, setIsVideosPlayable] = useState(false);
	const [hasVideoEnded, setHasVideoEnded] = useState(false);
	const [showCursorMask, setShowCursorMask] = useState(true);
	const [imageSupport, setImageSupport] = useState('');
	const [mbpsConnection, setMbpsConnection] = useState(0);
	const scrollLength = t(`buildings.pages.${buildingIndex}.sequence.scrollLength`);
	const globalContext = useContext(GlobalContext);
	const isMobileDevice = useMemo(() => {
		if (activateFullscreenMasking) {
			return isMobile();
		}
	}, [activateFullscreenMasking]);
	const isTouch = useMemo(() => {
		if (activateFullscreenMasking) {
			return isTouchDevice();
		}
	}, [activateFullscreenMasking]);
	const randomQuoteIndex = useMemo(() => {
		return Math.floor(Math.random() * loadingScreen.quotes.length);
	}, []);
	const nextBuildingIndex = useMemo(() => {
		return buildingIndex === allPages.length - 1
			? 0
			: buildingIndex + 1;
	}, []);
	const cookieDeclarationPath = useMemo(() => {
		const languageSlug = getLanguageSlug(i18n.language);
		return `${languageSlug}cookie-declaration`;
	}, []);

	const getVideoPoster = (fileName, images) => {
		const index = images.findIndex(image => image.node.childImageSharp.resize.originalName === fileName);
		return index > -1 ? images[index].node.childImageSharp.original.src : null;
	};

	const getImageObject = (fileName, images) => {
		const index = images.findIndex(image => image.node.childImageSharp.resize.originalName === fileName);
		return index > -1 ? images[index].node.childImageSharp.gatsbyImageData : null;
	};

	const changeImageSupport = type => {
		sessionStorage.setItem('imageSupport', type);
		return setImageSupport(type);
	};

	const getImageSupport = async cachedImageSupport => {
		if (cachedImageSupport) return setImageSupport(cachedImageSupport);
		const avif = await isAvifSupported();
		if (avif) return changeImageSupport('avif');
		const webp = await isWebpSupported();
		if (webp) return changeImageSupport('webp');
		return changeImageSupport('jpg');
	};

	const checkNetworkConnection = async cachedMbps => {
		if (cachedMbps) return setMbpsConnection(cachedMbps);
		const testImage = 'https://d1v34rham54jn.cloudfront.net/seo/shareImage_1200X630.jpg';
		const testImageSize = 623467; // Size of testImage in bytes
		const mbps = await testNetworkConnection(testImage, testImageSize);
		setMbpsConnection(mbps);
		return sessionStorage.setItem('mbps', mbps);
	};

	const createSectionPinningAnimation = () => {
		const mainElement = document.querySelector('main');

		ScrollTrigger.create({
			trigger: sequenceCanvasBlockRef.current,
			scrub: .5,
			start: 'top top',
			pin: true,
			pinSpacing: false,
			onEnter: () => mainElement.classList.add('scroll-cursor'),
		});

		ScrollTrigger.create({
			trigger: introDescription.current,
			scrub: 1,
			start: 'top top',
			pin: true,
			pinSpacing: false,
		});

		ScrollTrigger.create({
			trigger: footerTransitionRef.current,
			scrub: 1,
			start: 'top top',
			pin: true,
			pinSpacing: false,
		});

		ScrollTrigger.create({
			trigger: productsArticleRef.current,
			scrub: 1,
			start: 'bottom bottom',
			pin: true,
			pinSpacing: false,
		});
	};

	const getNextBuildingPath = () => {
		const isFrontpage = allPages[nextBuildingIndex].isFrontpage;
		const languageSlug = getLanguageSlug(i18n.language);

		if (isFrontpage) {
			return languageSlug;
		}
		if (buildingIndex < allPages.length - 1) {
			return `${languageSlug}${t(`buildings.pages.${nextBuildingIndex}.slug`)}`;
		}
		return `${languageSlug}${t('buildings.pages.0.slug')}`;
	};

	const animateBuildingTitle = reverseAnimation => {
		const title = new SplitText(buildingTitleRef.current, { type: 'lines' });
		const animation = { yPercent: reverseAnimation ? -100 : 100, opacity: 0, delay: reverseAnimation ? .1 : .5, duration: .4, ease: 'power4.out' };

		if (reverseAnimation) {
			return gsap.to(title.lines, animation);
		}
		return gsap.from(title.lines, animation);
	};

	const animateBuildingDetailsText = reverseAnimation => {
		const detailsText = new SplitText(buildingDetailsTextRef.current, { type: 'lines' });
		const animation = { yPercent: reverseAnimation ? -100 : 100, opacity: 0, delay: reverseAnimation ? .2 : .7, duration: .4, ease: 'power4.out', stagger: .05 };

		if (reverseAnimation) {
			return gsap.to(detailsText.lines, animation);
		}
		return gsap.from(detailsText.lines, animation);
	};

	const animateNavigationControls = reverseAnimation => {
		const animation = { opacity: 0, duration: .4, delay: reverseAnimation ? .1 : .5, ease: 'power4.out' };

		if (reverseAnimation) {
			return gsap.to(navigationControlsRef.current, animation);
		}
		return gsap.from(navigationControlsRef.current, animation);
	};

	const loadingProgressCompleted = () => {
		setTimeout(() => setLoadingCompleted(true), 500);
	};


	const onMoveFooterCursor = useCallback(event => {
		gsap.to(footerCursorShape.current, {
			x: parseInt(event.clientX),
			y: parseInt(event.clientY - footerTransitionRef.current.getBoundingClientRect().top),
			duration: .3,
			ease: 'power3.out',
		});
	}, [activateFullscreenMasking]);

	const toggleFooterCursorVisibility = show => {
		gsap.to(footerCursorShape.current, {
			duration: .25,
			opacity: show ? 1 : 0,
		});
	};

	const startBuildingExploration = () => {
		setActivateFullscreenMasking(true);
		if (window?.dataLayer) {
			window.dataLayer.push({'event': 'start_building_exploration'});
		}
	};

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger, SplitText);
		document.body.classList.add('overflow-hidden');	
		const sessionStorageMbpsConnection = sessionStorage.getItem('mbps');
		const sessionStorageImageSupport = sessionStorage.getItem('imageSupport');
		checkNetworkConnection(sessionStorageMbpsConnection);
		getImageSupport(sessionStorageImageSupport);

		return () => {
			const mainElement = document.querySelector('main');
			mainElement.classList.remove('scroll-cursor');
			setShowCursorMask(true);
			setLoadingCompleted(false);
			setShowDoubleArrows(false);
			setActivateFullscreenMasking(false);
			setIsVideosPlayable(false);
			setHasVideoEnded(false);
		};
	}, []);

	useEffect(() => {
		if (!showCursorMask) {
			document.body.classList.remove('overflow-hidden', !showCursorMask);
			document.body.classList.remove('fixed', !showCursorMask);	
			createSectionPinningAnimation();
		}
	}, [showCursorMask]);

	useEffect(() => {
		if (activateFullscreenMasking) {
			animateNavigationControls(true);
			animateBuildingTitle(true);
			animateBuildingDetailsText(true);
		}
	}, [activateFullscreenMasking]);

	useEffect(() => {
		if (isVideosPlayable) {
			animateBuildingTitle(false);
			animateBuildingDetailsText(false);
		}
	}, [isVideosPlayable]);

	useEffect(() => {
		if (hasVideoEnded) {
			animateNavigationControls(false);
		}
	}, [hasVideoEnded]);

	return (
		<Layout allPages={allPages} minHeight>
			<Meta
				lang={languages?.node.language}
				title={t(`buildings.pages.${buildingIndex}.name`)}
				description={t(`buildings.pages.${buildingIndex}.seo.description`)}
				ogTitle={t(`buildings.pages.${buildingIndex}.seo.title`)}
				ogImage={shareImageSoMe}
			/>
			{!isVideosPlayable && (
				<PortalBlock>
					<>
						<LoadingOverlayImage>
							{Array.from(allPages).map((_, index) =>
								<GatsbyImage
									key={index}
									alt="Loading"
									image={state.showFooterImage
										?	getImageObject(t(`buildings.pages.${index}.loadingScreenImage.exiting`), loadingImages)
										: getImageObject(t(`buildings.pages.${index}.loadingScreenImage.entering`), loadingImages)
									}
									className={clsx('object-cover w-screen h-screen', {
										'invisible': index !== buildingIndex || !state.showLoadingImage,
										'visible' : index === buildingIndex && state.showLoadingImage,
									})}
								/>,
							)}
						</LoadingOverlayImage>
						<LoadingOverlayBlock transparent={state.showLoadingImage}>
							<LoadingOverlayContainer>
								<div className="grid w-full grid-cols-4 text-center max-w-screen-2xl lg:grid-cols-12 lg:gap-x-16 gap-x-10 px-36 xl:px-0">
									<div className="col-span-4 lg:col-span-12">
										<ProgressBarBlock>
											<ProgressBarContainer
												viewBoxHeight={55}
												videos={videos}
												sequence={sequence}
												imageType={imageSupport}
												mbps={mbpsConnection}
												buildingIndex={buildingIndex}
												assetsFolderUri={assetsFolderUri}
												setCompleted={loadingProgressCompleted}
											>
												<ProgressBarLogoViewbox
													width={globalContext.windowWidth}
													height={globalContext.windowHeight}
													viewBox={`0 0 ${globalContext.windowWidth} ${globalContext.windowHeight}`}
												>
													<ProgressBarClipPath>
														<ProgressBarLogoPath
															masking
															center={state.showLoadingImage}
															fullscreenMasking={loadingCompleted}
															onComplete={() => setIsVideosPlayable(true)}
														/>
													</ProgressBarClipPath>
												</ProgressBarLogoViewbox>
												<ProgressBarLogoViewbox
													fill="#D20014"
													width={88}
													height={55}
													viewBox="0 0 91 55"
													fade={loadingCompleted}
												>
													<ProgressBarLogoPath />
												</ProgressBarLogoViewbox>
											</ProgressBarContainer>
										</ProgressBarBlock>
									</div>
									{state.showLoadingImage ? null : (
										<>
											<div className={clsx('col-span-4 lg:col-start-5', {
												'invisible': state.showLoadingImage,
											})}>
												<LoadingOverlayTitle>
													{t('loadingScreen.title')}
												</LoadingOverlayTitle>
												<LoadingOverlayDescription
													quotes={loadingScreen.quotes}
													randomIndex={randomQuoteIndex}
												/>
											</div>
											<div className={clsx('col-span-4 pt-16 lg:col-span-12', {
												'invisible': state.showLoadingImage,
											})}>
												<LoadingOverlayDetailText>
													{t('loadingScreen.progressText')}
												</LoadingOverlayDetailText>
											</div>
										</>
									)}
								</div>
							</LoadingOverlayContainer>
							<LoadingOverlayImage masking visible={loadingCompleted} invisible={!loadingCompleted}>
								{Array.from(allPages).map((_, index) =>
									<GatsbyImage
										key={index}
										alt="Loading"
										image={getImageObject(t(`buildings.pages.${index}.loadingScreenImage.exiting`), loadingImages)}
										className={clsx('object-cover w-screen h-screen', {
											'hidden': index !== buildingIndex,
											'block' : index === buildingIndex,
										})}
									/>,
								)}
							</LoadingOverlayImage>
						</LoadingOverlayBlock>
					</>
				</PortalBlock>
			)}
			{showCursorMask && (
				<VideoBlock visible={loadingCompleted} invisible={!loadingCompleted}>
					<VideoFrame
						muted playsInline
						play={isVideosPlayable}
						handleOnEnded={() => setHasVideoEnded(true)}
					>
						<VideoSource src={`${assetsFolderUri}/videos/${videos.background}`} type='video/mp4' />
					</VideoFrame>
				</VideoBlock>
			)}
			{hasVideoEnded && (
				<div ref={sequenceCanvasBlockRef} className="z-minus scroll-indicator" style={{ 'height': `${scrollLength}px` }}>
					<SequenceCanvas
						sequence={sequence}
						imageType={imageSupport}
						assetsFolderUri={assetsFolderUri}
						showCursorMask={showCursorMask}
						buildingIndex={buildingIndex}
					>
						{sequence.sections.map((_, index) =>
							<SequenceSectionBlock key={index}>
								<div className="grid grid-cols-4 lg:grid-cols-12 gap-x-10 lg:gap-x-16">
									<div className="col-span-4 lg:col-span-7">
										<SequenceSectionTitleGroup>
											<SequenceSectionSubtitle>
												{t(`buildings.pages.${buildingIndex}.sequence.sections.${index}.subtitle`)}
											</SequenceSectionSubtitle>
											<SequenceSectionTitle>
												{t(`buildings.pages.${buildingIndex}.sequence.sections.${index}.title`)}
											</SequenceSectionTitle>
										</SequenceSectionTitleGroup>
									</div>
									<div className="flex items-end col-span-4 lg:col-span-5">
										<SequenceSectionDescription
											content={t(`buildings.pages.${buildingIndex}.sequence.sections.${index}.description`)}
										/>
									</div>
								</div>
							</SequenceSectionBlock>,
						)}
					</SequenceCanvas>
				</div>
			)}
			<div ref={introDescription} className="z-minus">
				<DetailsArticleBlock invisible={!hasVideoEnded}>
					<section className="container mx-auto max-w-screen-3xl">
						<div className="grid grid-cols-4 lg:grid-cols-12 gap-x-10 lg:gap-x-16">
							<div className="col-span-4 lg:col-span-12">
								<DetailsArticleText intro content={t(`buildings.pages.${buildingIndex}.details.intro.descriptionHtml`)} />
							</div>
						</div>
					</section>
				</DetailsArticleBlock>
			</div>
			<DetailsArticleBlock invisible={!hasVideoEnded}>
				<section className="w-full h-screen-x2 lg:h-screen-width">
					<video
						autoPlay playsInline muted loop
						poster={getVideoPoster(t(`buildings.pages.${buildingIndex}.details.intro.video.poster`), fullwidthImages)}
						className="object-cover w-full h-full"
					>
						<source src={t(`buildings.pages.${buildingIndex}.details.intro.video.src`)} />
					</video>
				</section>
			</DetailsArticleBlock>
			<div ref={productsArticleRef}>
				<DetailsArticleBlock invisible={!hasVideoEnded}>
					<section className="container mx-auto max-w-screen-3xl pt-110 lg:pt-184 px-36 lg:px-16">
						<div className="grid grid-cols-4 lg:grid-cols-12 gap-x-10 lg:gap-x-16">
							<div className="col-span-4 lg:col-span-6 lg:col-start-2">
								<video
									autoPlay playsInline muted loop
									poster={getVideoPoster(t(`buildings.pages.${buildingIndex}.details.afterFullscreenVideo.video.poster`), fullwidthImages)}
									className="object-cover w-full h-full"
								>
									<source src={t(`buildings.pages.${buildingIndex}.details.afterFullscreenVideo.video.src`)} />
								</video>
							</div>
							<div className="flex items-end col-span-4 lg:col-span-3 lg:col-start-9 pt-55 lg:pt-0">
								<DetailsArticleText content={t(`buildings.pages.${buildingIndex}.details.afterFullscreenVideo.description`)} />
							</div>
						</div>
					</section>
					<section className="container pb-48 mx-auto max-w-screen-3xl pt-110 lg:pt-240 lg:pb-72 px-36 lg:px-16">
						<div className="grid grid-cols-4 lg:grid-cols-12 gap-x-10 lg:gap-x-16">
							<div className="col-span-1 text-right lg:col-start-2 lg:pt-8">
								<DetailsArticleText label uppercase content={t(`buildings.pages.${buildingIndex}.details.productsUsedLabel`)} />
							</div>
							<div className="col-span-4 pt-24 text-left lg:col-span-8 lg:text-center lg:pt-0">
								<h2 className="text-primary lg:text-100 text-60 leading-100">
									{t(`buildings.pages.${buildingIndex}.name`)}
								</h2>
							</div>
						</div>
					</section>
					{details.sections.map((section, index) => [
						section.columnSpan === 'full' && (
							<section key={index} className="grid grid-cols-4 lg:grid-cols-12 gap-x-10 lg:gap-x-16 px-36 4xl:pl-0 lg:pr-0">
								<div className="container flex col-span-12 mx-auto py-55 lg:py-80">
									<DetailsArticleInfoWrapper>
										<div className="grid grid-cols-4 gap-x-10 lg:gap-x-16 lg:gap-y-50 lg:pt-100 lg:pb-50 lg:w-455 lg:flex-none">
											<div className="col-span-4 pt-32 lg:col-span-1 lg:pt-0">
												<DetailsArticleText label content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.productNameLabel`)} />
											</div>
											<div className="col-span-4 pt-10 lg:col-span-3 lg:pt-0">
												<DetailsArticleText full content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.productNameText`)} />
											</div>
											<div className="col-span-4 pt-32 lg:col-span-1 lg:pt-0">
												<DetailsArticleText label content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.applicationLabel`)} />
											</div>
											<div className="col-span-4 pt-10 lg:col-span-3 lg:pt-0">
												<DetailsArticleText full content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.applicationText`)} />
											</div>
											{(t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteUrl`) || t(`buildings.pages.${buildingIndex}.details.bimUrl`)) && (
												<div className="col-span-4 lg:col-span-2 pt-34 lg:pt-0">
													{t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteUrl`) && (
														<DetailsArticleLink
															external
															bottomSpace
															url={i18n.language === 'en'
																? section.websiteUrl
																: t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteUrl`)
															}
														>
															<>
																<span>{t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteLabel`)}</span>
																<DetailsArticleLinkIcon image={externalLinkIcon} alt="External link" />
															</>
														</DetailsArticleLink>
													)}
													{t(`buildings.pages.${buildingIndex}.details.bimUrl`) && (
														<DetailsArticleLink
															external
															bottomSpace
															url={t(`buildings.pages.${buildingIndex}.details.bimUrl`)}
														>
															<>
																<span>{t(`buildings.pages.${buildingIndex}.details.bimLabel`)}</span>
																<DetailsArticleLinkIcon image={externalLinkIcon} alt="External link" />
															</>
														</DetailsArticleLink> 
													)}
												</div>
											)}
											<div className="col-span-4 lg:col-span-2">
												{t(`buildings.pages.${buildingIndex}.details.sections.${index}.downloadUrl`) && (
													<DetailsArticleLink
														download
														bottomSpace
														url={i18n.language === 'en'
															? section.downloadUrl
															: t(`buildings.pages.${buildingIndex}.details.sections.${index}.downloadUrl`)
														}
													>
														<>
															<span>{t(`buildings.pages.${buildingIndex}.details.sections.${index}.downloadLabel`)}</span>
															<DetailsArticleLinkIcon image={downloadIcon} alt="Download link" />
														</>
													</DetailsArticleLink>
												)}
												{t(`buildings.pages.${buildingIndex}.details.dopUrl`) && (
													<DetailsArticleLink
														external
														url={t(`buildings.pages.${buildingIndex}.details.dopUrl`)}
													>
														<>
															<span>{t(`buildings.pages.${buildingIndex}.details.dopLabel`)}</span>
															<DetailsArticleLinkIcon image={externalLinkIcon} alt="External link" />
														</>
													</DetailsArticleLink>
												)}
											</div>
										</div>
										<div className="relative w-full lg:pl-50">
											<p className="absolute font-bold text-right top-20 w-60 text-12">
												{(index + 1).toString().padStart(2, '0')}
											</p>
											{activateFullscreenMasking && (
												<video
													playsInline muted loop autoPlay={isMobileDevice}
													onMouseEnter={event => isMobileDevice ? null : event.target.play()}
													onMouseLeave={event => isMobileDevice ? null : event.target.pause()}
													poster={getVideoPoster(t(`buildings.pages.${buildingIndex}.details.sections.${index}.video.poster`), fullwidthImages)}
													className="object-cover w-full h-full bg-grey-100"
												>
													<source src={t(`buildings.pages.${buildingIndex}.details.sections.${index}.video.src`)} />
												</video>
											)}
										</div>
									</DetailsArticleInfoWrapper>
								</div>
							</section>
						),
						section.columnSpan === 'half' && (
							<DetailsArticleInfoWrapper key={index} halfColumn>
								<div className="flex flex-col">
									<div className="relative pb-32 lg:pb-24">
										<p className="absolute font-bold text-right top-20 w-60 text-12">
											{(index + 1).toString().padStart(2, '0')}
										</p>
										{activateFullscreenMasking && (
											<video
												playsInline muted loop autoPlay={isMobileDevice}
												onMouseEnter={event => isMobileDevice ? null : event.target.play()}
												onMouseLeave={event => isMobileDevice ? null : event.target.pause()}
												poster={getVideoPoster(t(`buildings.pages.${buildingIndex}.details.sections.${index}.video.poster`), fullwidthImages)}
												className="object-cover w-full h-full bg-grey-100"
											>
												<source src={t(`buildings.pages.${buildingIndex}.details.sections.${index}.video.src`)} />
											</video>
										)}
									</div>
									<div className="container w-full mx-auto">
										<div className="grid grid-cols-4 lg:grid-cols-6 gap-x-10 lg:gap-x-16">
											<div className="col-span-4 lg:col-span-2 pb-34 lg:pb-0">
												<div className="pb-10 lg:pb-6">
													<DetailsArticleText label content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.productNameLabel`)} />
												</div>
												<DetailsArticleText content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.productNameText`)} />
											</div>
											<div className="col-span-4 pb-34 lg:pb-0">
												<div className="pb-10 lg:pb-6">
													<DetailsArticleText label content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.applicationLabel`)} />
												</div>
												<DetailsArticleText content={t(`buildings.pages.${buildingIndex}.details.sections.${index}.applicationText`)} />
											</div>
											{(t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteUrl`) || t(`buildings.pages.${buildingIndex}.details.bimUrl`)) && (
												<div className="w-full col-span-4 lg:col-span-2 lg:pt-24">
													{t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteUrl`) && (
														<DetailsArticleLink
															external
															bottomSpace
															url={i18n.language === 'en'
																? section.websiteUrl
																: t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteUrl`)
															}
														>
															<>
																<span>{t(`buildings.pages.${buildingIndex}.details.sections.${index}.websiteLabel`)}</span>
																<DetailsArticleLinkIcon image={externalLinkIcon} alt="External link" />
															</>
														</DetailsArticleLink>
													)}
													{t(`buildings.pages.${buildingIndex}.details.bimUrl`) && (
														<DetailsArticleLink
															external
															bottomSpace
															url={t(`buildings.pages.${buildingIndex}.details.bimUrl`)}
														>
															<>
																<span>{t(`buildings.pages.${buildingIndex}.details.bimLabel`)}</span>
																<DetailsArticleLinkIcon image={externalLinkIcon} alt="External link" />
															</>
														</DetailsArticleLink>
													)}
												</div>
											)}
											<div className="col-span-3 pt-0 lg:pt-24">
												{t(`buildings.pages.${buildingIndex}.details.sections.${index}.downloadUrl`) && (
													<DetailsArticleLink
														download
														bottomSpace
														url={i18n.language === 'en'
															? section.downloadUrl
															: t(`buildings.pages.${buildingIndex}.details.sections.${index}.downloadUrl`)
														}
													>
														<>
															<span>{t(`buildings.pages.${buildingIndex}.details.sections.${index}.downloadLabel`)}</span>
															<DetailsArticleLinkIcon image={downloadIcon} alt="Download link" />
														</>
													</DetailsArticleLink>
												)}
												{t(`buildings.pages.${buildingIndex}.details.dopUrl`) && (
													<DetailsArticleLink
														external
														url={t(`buildings.pages.${buildingIndex}.details.dopUrl`)}
													>
														<>
															<span>{t(`buildings.pages.${buildingIndex}.details.dopLabel`)}</span>
															<DetailsArticleLinkIcon image={externalLinkIcon} alt="External link" />
														</>
													</DetailsArticleLink>
												)}
											</div>
										</div>
									</div>
								</div>
							</DetailsArticleInfoWrapper>
						)],
					)}
				</DetailsArticleBlock>
			</div>
			<div ref={footerTransitionRef}>
				<BuildingTransitionBlock
					footer
					invisible={!hasVideoEnded}
					onMouseMove={onMoveFooterCursor}
					onMouseEnter={() => toggleFooterCursorVisibility(true)}
					onMouseLeave={() => toggleFooterCursorVisibility(false)}
				>
					<TransitionLink
						to={getNextBuildingPath()}
						exit={{ length: 1 }}
						entry={{
							state: {
								showLoadingImage: true,
								showFooterImage: true,
							},
							length: 1,
						}}
						className="relative h-screen cursor-none"
					>
						<GatsbyImage
							className="object-cover w-screen h-screen"
							loading="lazy"
							image={getImageObject(t(`buildings.pages.${nextBuildingIndex}.loadingScreenImage.exiting`), loadingImages)}
							alt={t(`buildings.pages.${nextBuildingIndex}.name`)}
						/>
						<BuildingTransitionSvg
							width={globalContext.windowWidth}
							height={globalContext.windowHeight}
						>
							<BuildingTransitionSvgGroup ref={footerCursorShape}>
								<BuildingTransitionIconPath
									strokeWidth={isTouch ? '2' : '1'}
									d={isTouch
										? 'M102.91,119.28h42.28L93.72,33.77H83.57V87.15Zm-95,0H50.19L69.52,87.16V33.77H59.37Z'
										: 'm63.576 55.1-12.34-20.507V.5h6.9l32.862 54.6Zm-60.332 0L36.102.5h6.9v34.1l-12.34 20.5Z'
									}
								/>
								<BuildingTransitionIconTextGroup
									fontSize={isTouch ? '16' : '9'}
									transform={isTouch ? 'translate(0 140)' : 'translate(0 64.5)'}
								>
									<>
										<BuildingTransitionIconTextSpan x="0" y="9">
											{t('global.frontpageCursorFirstLine')}
										</BuildingTransitionIconTextSpan>
										<BuildingTransitionIconTextSpan x="0" y={isTouch ? '30' : '21'}>
											{t('global.frontpageCursorSecondLine')}
										</BuildingTransitionIconTextSpan>
									</>
								</BuildingTransitionIconTextGroup>
							</BuildingTransitionSvgGroup>
						</BuildingTransitionSvg>
						<div className="grid grid-cols-4 max-w-screen-3xl lg:grid-cols-12 gap-x-10 lg:gap-x-16">
							<div className="col-span-4 lg:col-span-12">
								<BuildingTransitionWrapper>
									<div className="grid grid-cols-4 mx-auto lg:grid-cols-12 gap-x-10 lg:gap-x-16 max-w-screen-3xl">
										<div className="col-span-4 px-36 4xl:px-0 lg:col-span-12">
											<BuildingTransitionTitle>
												{t(`buildings.pages.${nextBuildingIndex}.name`)}
											</BuildingTransitionTitle>
											<BuildingTransitionText>
												<b>{`${t('global.location')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.location`)}`}<br />
												<b>{`${t('global.buildingStart')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.started`)}`} <b>{`${t('global.buildingEnd')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.ended`)}`} <b>{`${t('global.areal')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.areal`)}`} m<sup>2</sup><br />
												<b>{`${t('global.contractor')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.contractor`)}`} <b>{`${t('global.architects')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.architects`)}`}<br />
												<b>{`${t('global.engineer')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.engineer`)}`} <b>{`${t('global.entrepreneur')}:`}</b> {`${t(`buildings.pages.${nextBuildingIndex}.details.entrepreneur`)}`}
											</BuildingTransitionText>
										</div>
									</div>
								</BuildingTransitionWrapper>
							</div>
						</div>
					</TransitionLink>
				</BuildingTransitionBlock>
			</div>
			{activateFullscreenMasking && (
				<FooterBlock>
					<div className="grid grid-cols-4 mx-auto max-w-screen-3xl lg:grid-cols-12 gap-x-10 lg:gap-x-16 px-36 4xl:px-0">
						<div className="col-span-2">
							<FooterContent content={t('footer.company.address')} />
						</div>
						<div className="col-span-2">
							<FooterContent content={t('footer.company.details')} />
						</div>
						<div className="col-span-4 lg:col-span-3">
							<ul className="grid grid-cols-4 gap-16 py-16 lg:grid-cols-3 lg:gap-0 lg:py-0">
								{footer.someLinks.map((_, index) =>
									<li key={index} className="col-span-2 lg:col-span-1">
										<FooterLink url={t(`footer.someLinks.${index}.url`)}>
											{t(`footer.someLinks.${index}.label`)}
										</FooterLink>
									</li>,
								)}
							</ul>
						</div>
						<div className="col-span-4 text-left lg:col-span-2">
							<ul className="grid grid-cols-4 gap-16 pb-16 lg:gap-0 lg:pb-0">
								<li className="col-span-4">
									<FooterLink highlight url={t('footer.company.contact.url')}>
										{t('footer.company.contact.label')}
									</FooterLink>
								</li>
								<li className="col-span-4">
									<FooterLink self url={cookieDeclarationPath} className="footer__link">
										{t('cookieDeclaration.displayTitle')}
									</FooterLink>
								</li>
							</ul>
						</div>
					</div>
				</FooterBlock>
			)}
			<div className="absolute top-0 left-0 w-full" style={{'height': `${globalContext.windowHeight}px`}}>
				{(hasVideoEnded && showCursorMask) && (
					<button onClick={() => startBuildingExploration()} className="absolute top-0 left-0 w-full h-full" id="building-exploration-button">
						<CursorMask
							showMaskingFullscreen={activateFullscreenMasking}
							showDoubleArrows={showDoubleArrows}
							setShowCursorMask={setShowCursorMask}
							backgroundImage={`${assetsFolderUri}/images/${imageSupport}/${t(`buildings.pages.${buildingIndex}.sequence.filePrefix`)}0000.${imageSupport}`}
						/>
					</button>
				)}
				<div className="container w-full h-full mx-auto max-w-screen-3xl">
					<div className="grid w-full h-full grid-cols-4 xl:grid-cols-12 gap-x-16 px-36 xl:px-0">
						{isVideosPlayable && (
							<div className="hidden col-span-6 xl:block">
								<div className="flex items-end h-full pb-80 pl-36 4xl:pl-0">
									<div className="overflow-y-hidden text-white ">
										<h1 ref={buildingTitleRef} className="pb-10 text-50 leading-137">
											{t(`buildings.pages.${buildingIndex}.name`)}
										</h1>
										<p ref={buildingDetailsTextRef}>
											<b>{`${t('global.location')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.location`)}`}<br />
											<b>{`${t('global.buildingStart')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.started`)}`} <b>{`${t('global.buildingEnd')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.ended`)}`} <b>{`${t('global.areal')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.areal`)}`} m<sup>2</sup><br />
											<b>{`${t('global.contractor')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.contractor`)}`} <b>{`${t('global.architects')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.architects`)}`}<br />
											<b>{`${t('global.engineer')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.engineer`)}`} <b>{`${t('global.entrepreneur')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.entrepreneur`)}`}
										</p>
									</div>
								</div>
							</div>
						)}
						{(hasVideoEnded && showCursorMask) && (
							<div ref={navigationControlsRef} className="flex items-end col-span-4 xl:col-span-6 xl:block">
								<div
									onMouseEnter={() => setShowDoubleArrows(true)}
									onMouseLeave={() => setShowDoubleArrows(false)}
									className="fixed bottom-0 left-0 w-full text-right text-black bg-grey-400 xl:relative xl:text-white xl:bg-transparent xl:uppercase xl:bottom-auto xl:left-auto"
								>
									<div className="px-36 pt-18 pb-28 xl:px-0 xl:pt-0 xl:pb-0">
										<BuildingTransitionBlock spaceBottom>
											<BuildingTransitionLink to={getNextBuildingPath()} entryState={{ showLoadingImage: true }}>
												<>
													{buildingIndex < allPages.length - 1
														? <BuildingTransitionLabel desktop>{t('buildings.shared.nextCaseLabel')}: {(buildingIndex + 2).toString().padStart(2, '0')} {t(`buildings.pages.${buildingIndex + 1}.name`)}</BuildingTransitionLabel>
														: <BuildingTransitionLabel desktop>{t('buildings.shared.nextCaseLabel')}: 01 {t('buildings.pages.0.name')}</BuildingTransitionLabel>
													}
												</>
											</BuildingTransitionLink>
											<BuildingTransitionFlexbox justifyBetween mobile>
												<BuildingTransitionFlexbox justifyStart>
													{Array.from(allPages).map((_, index) =>
														<BuildingTransitionIndexDot key={index} current={index === buildingIndex}>
															<span className="sr-only">
																{t(`buildings.pages.${index}.name`)}
															</span>
														</BuildingTransitionIndexDot>,
													)}
												</BuildingTransitionFlexbox>
												<BuildingTransitionLink relative to={getNextBuildingPath()} entryState={{ showLoadingImage: true }}>
													<BuildingTransitionFlexbox justifyEnd>
														<BuildingTransitionLabel>{t('buildings.shared.nextCaseLabel')}</BuildingTransitionLabel>
														<BuildingTransitionIcon src={tapButtonIcon} alt={t('buildings.shared.goTo')} />
													</BuildingTransitionFlexbox>
												</BuildingTransitionLink>
											</BuildingTransitionFlexbox>
										</BuildingTransitionBlock>
										<div className="block text-left xl:hidden">
											<h1 className="mb-8 text-20">{(buildingIndex + 1).toString().padStart(2, '0')} {t(`buildings.pages.${buildingIndex}.name`)}</h1>
											<p className="text-14">
												<b>{`${t('global.location')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.location`)}`}<br />
												<b>{`${t('global.buildingStart')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.started`)}`} <b>{`${t('global.buildingEnd')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.ended`)}`} <b>{`${t('global.areal')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.areal`)}`} m<sup>2</sup><br />
												<b>{`${t('global.contractor')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.contractor`)}`} <b>{`${t('global.architects')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.architects`)}`}<br />
												<b>{`${t('global.engineer')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.engineer`)}`} <b>{`${t('global.entrepreneur')}:`}</b> {`${t(`buildings.pages.${buildingIndex}.details.entrepreneur`)}`}
											</p>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default BuildingTemplate;

export const query = graphql`
  query($language: String!, $imagesDirectory: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
		fullwidthImages: allFile(filter: {relativeDirectory: {eq: $imagesDirectory}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(
							layout: FULL_WIDTH
							formats: [AVIF, WEBP, AUTO]
							placeholder: BLURRED
						)
						resize {
							originalName
						}
						original {
							src
						}
					}
				}
			}
		}
		loadingImages: allFile(filter: {relativeDirectory: {eq: "loadingscreen"}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(
							layout: FULL_WIDTH
							formats: [AVIF, WEBP, AUTO]
							placeholder: BLURRED
						)
						resize {
							originalName
						}
					}
				}
			}
		}
  }
`;