import React, { useRef, useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import cover from 'canvas-image-cover';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile, getMaxFrames } from './../utils/helpers';
import { usePrevious } from './../utils/custom-hooks';

const SequenceCanvas = ({ imageType, sequence, assetsFolderUri, showCursorMask, buildingIndex, children }) => {
	const { sections } = sequence;
	const [currentSequenceSection, setCurrentSequenceSection] = useState(-1);
	const [currentFrame, setCurrentFrame] = useState(0);
	const sectionsContaioner = useRef();
	const sectionTimeline = useRef();
	const canvasRef = useRef();
	const [isNotScrolling, setIsNotScrolling] = useState(true);
	const { t } = useTranslation();
	const images = [];
	const building = {
		frame: 0,
	};
	let resizingTimeout;
	let idlingTimeout;
	const totalFrames = t(`buildings.pages.${buildingIndex}.sequence.totalFrames`);
	const scrollLength = t(`buildings.pages.${buildingIndex}.sequence.scrollLength`);
	const maxSequenceFrames = useMemo(() => {
		const mbps = sessionStorage.getItem('mbps');
		return getMaxFrames(totalFrames, mbps);
	}, []);

	const setupCanvas = () => {
		const canvasComputedHeight = canvasRef.current.clientHeight;
		canvasRef.current.width = document.documentElement.clientWidth;
		canvasRef.current.height = canvasComputedHeight;
	};

	const getFrame = index => {
		const filePrefix = t(`buildings.pages.${buildingIndex}.sequence.filePrefix`);
		const imageIndex = (index).toString().padStart(4, '0');
		return `${assetsFolderUri}/images/${imageType}/${filePrefix}${imageIndex}.${imageType}`;
	};

	const getAllImages = () => {
		const ratio = Math.ceil(totalFrames / maxSequenceFrames);
		for (let index = 0; index < totalFrames; index++) {
			if (index % ratio === 0) {
				const image = new Image();
				image.src = getFrame(index);
				images.push(image);
			}
		}
	};

	const toggleScrollCursor = showCursor => {
		if (!showCursorMask) {
			const mainElement = document.querySelector('main');
			mainElement.classList.toggle('scroll-cursor', showCursor);
		}
	};

	const detectScrollIdling = () => {
		clearTimeout(idlingTimeout);
		setIsNotScrolling(false);

		idlingTimeout = setTimeout(() => {
			setIsNotScrolling(true);
		}, 2000);
	};

	const renderCanvas = () => {
		if(!canvasRef.current) { return; }
		const context = canvasRef.current.getContext('2d');
		cover(images[building.frame], 0, 0, canvasRef.current.width, canvasRef.current.height).render(context);
		sectionToAnimate(building.frame);
		setCurrentFrame(building.frame);
		toggleScrollCursor(building.frame < 50);
		detectScrollIdling();
	};

	const sectionToAnimate = frame => {
		const ratio = Math.ceil(totalFrames / maxSequenceFrames);
		const sectionInViewport = sections.findIndex(section => frame >= (section.showOnFrame / ratio)  && frame <= (section.hideOnFrame / ratio));
		setCurrentSequenceSection(sectionInViewport);
	};

	const initScrollTrigger = () => {
		gsap.to(building, {
			frame: maxSequenceFrames - 1,
			snap: 'frame',
			scrollTrigger: {
				scrub: .5,
				end: `+=${scrollLength}`,
			},
			onUpdate: renderCanvas,
		});
	};

	const resizeCanvas = () => {
		clearTimeout(resizingTimeout);
		resizingTimeout = setTimeout(() => {
			setupCanvas();
			renderCanvas();
		}, 100);
	};

	const renderFirstFrame = () => {
		const firstFrame = getFrame(0);
		const framePromise = new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = () => resolve();
			image.onerror = () => reject();
			image.src = firstFrame;
		});

		framePromise
			.then(() => renderCanvas())
			.catch(error => console.error(error));
	};

	const createProductTextAnimation = () => {
		const sections = gsap.utils.toArray(sectionsContaioner.current.querySelectorAll('.js-sequence-section'));

		sections.forEach((section, index) => {
			const titleElement = section.querySelector('.js-section-title');
			const subtitleElement = section.querySelector('.js-section-subtitle');
			const descriptionElement = section.querySelector('.js-section-description');
			const headline = new SplitText(titleElement, { type: 'lines' });
			const descriptionLines = new SplitText(descriptionElement, { type: 'lines' });

			sectionTimeline.current.addLabel(`start-showing-section-${index}`, index * 3);
			sectionTimeline.current.set(
				section, { autoAlpha: 1 },
				`start-showing-section-${index}`,
			);
			sectionTimeline.current.from(
				subtitleElement, { opacity: 0 },
				`start-showing-section-${index}`,
			);
			sectionTimeline.current.from(
				headline.lines, { duration: .5, yPercent: 100, opacity: isMobile() ? 0 : 1, stagger: .05, ease: 'power4.out' },
				`start-showing-section-${index}`,
			);
			sectionTimeline.current.from(
				descriptionLines.lines, { duration: .3, opacity: 0, yPercent: 100, stagger: .1, delay: .2 },
				`start-showing-section-${index}`,
			);
			sectionTimeline.current.addLabel(`end-showing-section-${index}`, (index * 3) + 1.5);
			sectionTimeline.current.addLabel(`start-hiding-section-${index}`, (index * 3) + 1.5);
			sectionTimeline.current.to(
				subtitleElement, { opacity: 0 },
				`start-hiding-section-${index}`,
			);
			sectionTimeline.current.to(
				headline.lines, { duration: .3, yPercent: -100, opacity: isMobile() ? 0 : 1, stagger: .05 },
				`start-hiding-section-${index}`,
			);
			sectionTimeline.current.to(
				descriptionLines.lines, { duration: .3, opacity: 0, yPercent: -100, stagger: .1 },
				`start-hiding-section-${index}`,
			);
			sectionTimeline.current.set(
				section, { autoAlpha: 0 },
				`start-showing-section-${index}+=${(index * 3) + 3}`,
			);
			sectionTimeline.current.addLabel(`end-hiding-section-${index}`, (index * 3) + 3);
		});
		sectionTimeline.current.pause();
	};

	const previousSection = usePrevious(currentSequenceSection);

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger, SplitText);
		sectionTimeline.current = gsap.timeline({
			defaults: {
				duration: .2,
				ease: 'power2.inOut',
			},
		});
	}, []);

	useEffect(() => {
		if (currentSequenceSection === -1) {
			sectionTimeline.current.tweenFromTo(
				`start-hiding-section-${previousSection}`,
				`end-hiding-section-${previousSection}`,
			);
		}
		if (currentSequenceSection > -1) {
			sectionTimeline.current.tweenFromTo(
				`start-showing-section-${currentSequenceSection}`,
				`end-showing-section-${currentSequenceSection}`,
			);
		}
	}, [currentSequenceSection]);

	useEffect(() => {
		if (imageType) {
			setupCanvas();
			getAllImages();
			renderFirstFrame();
			createProductTextAnimation();
			window.addEventListener('resize', resizeCanvas);
			return () => window.removeEventListener('resize', resizeCanvas);
		}
	}, [imageType]);

	useEffect(() => {
		if (!showCursorMask) {
			getAllImages();
			initScrollTrigger();
		}
	}, [showCursorMask]);

	return (
		<div className={clsx('sequence-canvas', {
			'sequence-canvas--with-clip-path': showCursorMask,
		})}>
			<div className="sequence-canvas__container">
				<canvas ref={canvasRef} className="sequence-canvas__frame" />
				<div className={clsx('sequence-canvas__progress-wrapper', {
					'sequence-canvas__progress-wrapper--scroll-idle': isNotScrolling,
				})}>
					<progress value={currentFrame} max={maxSequenceFrames - 1} className="sequence-canvas__progress-bar" />
				</div>
				<div
					ref={sectionsContaioner}
					className={clsx('sequence-canvas__sections-container', {
						'sequence-canvas__sections-container--hidden': showCursorMask,
					})}
				>
					{children}
				</div>
			</div>
			{currentFrame < 3 ? <span className='sequence-canvas__scroll-indicator'>SCROLL</span> : null}
		</div>
	);
};

export default SequenceCanvas;