import React from 'react';
import clsx from 'clsx';

export const DetailsArticleBlock = ({ children, invisible }) => (
	<article className={clsx('details-article', {
		'details-article--invisible': invisible,
	})}>
		{children}
	</article>
);

export const DetailsArticleText = ({ content, intro, label, uppercase, full }) => (
	<div
		dangerouslySetInnerHTML={{ __html: content }}
		className={clsx('details-article__text', {
			'details-article__text--intro': intro,
			'details-article__text--label': label,
			'details-article__text--uppercase': uppercase,
			'details-article__text--full': full,
		})}
	/>
);

export const DetailsArticleImageWrapper = ({ children, fullscreen }) => (
	<div className={clsx('details-article__image-wrapper', {
		'details-article__image-wrapper--fullscreen': fullscreen,
	})}>
		{children}
	</div>
);

export const DetailsArticleInfoWrapper = ({ children, halfColumn }) => (
	<div className={clsx('details-article__info-wrapper', {
		'details-article__info-wrapper--half-column': halfColumn,
	})}>
		{children}
	</div>
);

export const DetailsArticleLink = ({ url, children, external, download, bottomSpace }) => (
	<a
		href={url}
		rel="noopener noreferrer"
		target="_blank"
		className={clsx('details-article__link-wrapper', {
			'link--external': external,
			'link--download': download,
			'details-article__link-wrapper--sibling-space': bottomSpace,
		})}>
		{children}
	</a>
);

export const DetailsArticleLinkIcon = ({ image, alt }) => (
	<img src={image} alt={alt} className="details-article__link-icon" />
);