import React from 'react';

export const SequenceSectionBlock = ({ children }) => (
	<div className="sequence-section js-sequence-section">
		{children}
	</div>
);

export const SequenceSectionTitleGroup = ({ children }) => (
	<div className="sequence-section__title-group">
		{children}
	</div>
);

export const SequenceSectionSubtitle = ({ children }) => (
	<p className="sequence-section__subtitle js-section-subtitle">
		{children}
	</p>
);

export const SequenceSectionTitle = ({ children }) => (
	<h1 className="sequence-section__title js-section-title">
		{children}
	</h1>
);

export const SequenceSectionDescription = ({ content }) => (
	<p
		dangerouslySetInnerHTML={{ __html: content }}
		className="sequence-section__description js-section-description"
	/>
);