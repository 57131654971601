import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export const PortalBlock = ({ children }) => {
	const overlayRoot = useRef();

	useEffect(() => {
		const childRoot = document.createElement('div');
		overlayRoot.current = document.querySelector('.js-portal-spawnpoint');
		overlayRoot.current.appendChild(childRoot);

		return () => {
			overlayRoot.current.removeChild(childRoot);
		};
	}, []);

	return overlayRoot.current ? (
		createPortal(
			<>{children}</>,
			overlayRoot.current,
		)
	) : (
		<div className="portal" />
	);
};