import React, { useEffect, useState, useRef, useContext } from 'react';
import clsx from 'clsx';
import gsap from 'gsap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import GlobalContext from '../context/global-context';
import { getMaxFrames } from './../utils/helpers';

export const ProgressBarBlock = ({ children }) => (
	<div className="progress-bar">
		{children}
	</div>
);

export const ProgressBarContainer = ({
	children,
	setCompleted,
	videos,
	imageType,
	mbps,
	buildingIndex,
	assetsFolderUri,
	viewBoxHeight,
}) => {
	const [loaded, setLoaded] = useState(0);
	const [totalFiles, setTotalFiles] = useState(1);
	const { t } = useTranslation();

	const getAllImages = ratio => {
		const images = [];
		const filePrefix = t(`buildings.pages.${buildingIndex}.sequence.filePrefix`);
		const totalFrames = t(`buildings.pages.${buildingIndex}.sequence.totalFrames`);

		for (let index = 0; index <= totalFrames - 1; index++) {
			if (index % ratio === 0) {
				const imageIndex = (index).toString().padStart(4, '0');
				images.push(`${assetsFolderUri}/images/${imageType}/${filePrefix}${imageIndex}.${imageType}`);
			}
		}
		return images;
	};

	const getAllVideos = () => {
		const { background } = videos;
		const backgroundVideo = `${assetsFolderUri}/videos/${background}`;
		return backgroundVideo;
	};

	const getAllAssets = ratio => {
		const allVideos = getAllVideos();
		const allImages = getAllImages(ratio);
		const allAssets = [allVideos, allImages];
		return allAssets.flat();
	};

	const loadImage = file => {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = () => resolve();
			image.onerror = () => reject();
			image.src = file;
		});
	};

	const loadVideo = file => {
		return new Promise((resolve, reject) => {
			const videoElement = document.createElement('video');
			videoElement.src = file;
			videoElement.load();
			videoElement.addEventListener('error', () => reject());

			const videosReadyCheckInterval = setInterval(() => {
				if (videoElement.readyState >= 3) {
					resolve();
					clearInterval(videosReadyCheckInterval);
				}
			}, 100);
		});
	};

	const getFileExtension = file => file.split('.').pop();

	const checkFileType = async file => {
		const fileExtension = getFileExtension(file);

		switch(fileExtension) {
		case 'mp4':
		case 'webm':
			return await loadVideo(file);
		case 'jpg':
		case 'png':
		case 'webp':
		case 'avif':
			return await loadImage(file);
		default:
			return new Error('Unsupported filetype found: ', fileExtension);
		}
	};

	const allPromises = files => {
		return files
			.map(file => {
				return checkFileType(file)
					.then(() => setLoaded(prevLoaded => prevLoaded + 1))
					.catch(error => console.error(error));
			});
	};

	const startFetching = () => {
		const totalFrames = t(`buildings.pages.${buildingIndex}.sequence.totalFrames`);
		const maxFrames = getMaxFrames(totalFrames, mbps);
		const ratio = Math.ceil(totalFrames / maxFrames);
		const files = getAllAssets(ratio);
		setTotalFiles(maxFrames);

		Promise.all(allPromises(files))
			.then(() => setCompleted())
			.catch(error => console.error(error));
	};

	useEffect(() => {
		if (imageType && mbps) {
			startFetching();
		}
	}, [imageType, mbps]);

	return (
		<div className="progress-bar__container" style={{ 'height': `${ ((loaded / totalFiles) * viewBoxHeight).toFixed(0) }px` }}>
			{children}
		</div>
	);
};

export const ProgressBarLogoViewbox = ({ children, width, height, viewBox, fill, fade }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}	
		viewBox={viewBox}
		fill={fill}
		className={clsx('progress-bar__logo-viewbox', {
			'progress-bar__logo-viewbox--fadeout': fade,
		})}
	>
		{children}
	</svg>
);

export const ProgressBarClipPath = ({ children }) => (
	<clipPath id="loading-mask" className="progress-bar__clip-path">
		{children}
	</clipPath>
);

export const ProgressBarLogoPath = ({ center, masking, onComplete, fullscreenMasking }) => {
	const pathRef = useRef();
	const globalContext = useContext(GlobalContext);

	const triggerFullscreenAnimation = () => {
		gsap.to(pathRef.current, {
			x: 0, y: 0,
			morphSVG: `
				M${globalContext.windowWidth / 2},${globalContext.windowHeight}
				H${globalContext.windowWidth}
				V0
				H${globalContext.windowWidth / 2}
				V${globalContext.windowHeight}Z
				M0,${globalContext.windowHeight}
				H${globalContext.windowWidth / 2}
				V0
				H0
			`,
			ease: 'power4.inOut',
			delay: .5,
			duration: 1,
			onComplete,
		});
	};

	useEffect(() => {
		gsap.registerPlugin(MorphSVGPlugin);
	}, []);

	useEffect(() => {
		if (fullscreenMasking) {
			triggerFullscreenAnimation();
		}
	}, [fullscreenMasking]);

	return (
		<path
			ref={pathRef}
			d="m63.576 55.1-12.34-20.507V.5h6.9l32.862 54.6Zm-60.332 0L36.102.5h6.9v34.1l-12.34 20.5Z"
			className={clsx('progress-bar__logo-path', {
				'progress-bar__logo-path--masking': masking,
				'progress-bar__logo-path--centered-mask': center,
			})}
		/>
	);
};