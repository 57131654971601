import React, { forwardRef } from 'react';
import clsx from 'clsx';
import TransitionLink from 'gatsby-plugin-transition-link';

export const BuildingTransitionBlock = ({
	children,
	spaceBottom,
	footer,
	invisible,
	onMouseMove,
	onMouseEnter,
	onMouseLeave,
}) => (
	<div
		onMouseMove={onMouseMove}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		className={clsx('building-transition', {
			'building-transition--space-bottom': spaceBottom,
			'building-transition--footer': footer,
			'building-transition--invisible': invisible,
		})}
	>
		{children}
	</div>
);

export const BuildingTransitionLink = ({ children, to, relative, entryState }) => (
	<TransitionLink
		to={to}
		exit={{ length: 1 }}
		entry={{ state: entryState, length: 1 }}
		className={clsx('building-transition__link', {
			'building-transition__link--relative': relative,
		})}
	>
		{children}
	</TransitionLink>
);

export const BuildingTransitionLabel = ({ children, desktop }) => (
	<span className={clsx('building-transition__label', {
		'building-transition__label--desktop': desktop,
	})}>
		{children}
	</span>
);

export const BuildingTransitionIcon = ({ src, alt }) => (
	<img src={src} alt={alt} className="building-transition__icon" />
);

export const BuildingTransitionFlexbox = ({ children, justifyBetween, justifyStart, justifyEnd, mobile }) => (
	<div className={clsx('building-transition__flex', {
		'building-transition__flex--justify-between': justifyBetween,
		'building-transition__flex--justify-start': justifyStart,
		'building-transition__flex--justify-end': justifyEnd,
		'building-transition__flex--mobile': mobile,
	})}>
		{children}
	</div>
);

export const BuildingTransitionIndexDot = ({ children, current }) => (
	<div className={clsx('building-transition__index-dot', {
		'building-transition__index-dot--current': current,
	})}>
		{children}
	</div>
);

export const BuildingTransitionWrapper = ({ children }) => (
	<div className="building-transition__wrapper">
		{children}
	</div>
);

export const BuildingTransitionTitle = ({ children }) => (
	<h1 className="building-transition__title">
		{children}
	</h1>
);

export const BuildingTransitionText = ({ children }) => (
	<p className="building-transition__text">
		{children}
	</p>
);

export const BuildingTransitionSvg = ({ children, width, height }) => (
	<svg className="building-transition__svg" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
		{children}
	</svg>
);

export const BuildingTransitionSvgGroup = forwardRef(
	function BuildingTransitionSvgGroup({ children }, ref) {
		return (
			<g className="building-transition__svg-group" ref={ref}>
				{children}
			</g>
		);
	},
);

export const BuildingTransitionIconPath = ({ strokeWidth, d }) => (
	<path
		stroke="#fff"
		strokeWidth={strokeWidth}
		fill="none"
		className="building-transition__icon-path"
		d={d}
	/>
);

export const BuildingTransitionIconTextGroup = ({ children, transform, fontSize }) => (
	<text xmlns="http://www.w3.org/2000/svg" transform={transform} fill="#fff" fontSize={fontSize} fontFamily="Avenir-Heavy, Avenir" fontWeight="800">
		{children}
	</text>
);

export const BuildingTransitionIconTextSpan = ({ children, x, y }) => (
	<tspan textAnchor="middle" x={x} y={y}>
		{children}
	</tspan>
);