import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';

export const VideoBlock = ({ children, visible, invisible, masking }) => (
	<div className={clsx('video', {
		'video--visible': visible,
		'video--invisible': invisible,
		'video--masking': masking,
	})}>
		{children}
	</div>
);

export const VideoFrame = ({ children, play, muted, playsInline, handleOnEnded }) => {
	const introVideoRef = useRef();

	useEffect(() => {
		if (play) {
			introVideoRef.current.play();
		}
	}, [play]);

	return (
		<video
			muted={muted}
			playsInline={playsInline}
			ref={introVideoRef}
			onEnded={handleOnEnded}
			className='video__frame'
		>
			{children}
		</video>
	);
};

export const VideoSource = ({ src, type }) => (
	<source src={src} type={type} />
);