import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import clsx from 'clsx';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const LoadingOverlayBlock = ({ children, fade, transparent }) => (
	<div className={clsx('loading-overlay', {
		'loading-overlay--fadeout': fade,
		'loading-overlay--transparent': transparent,
	})}>
		{children}
	</div>
);

export const LoadingOverlayContainer = ({ children, bottom }) => (
	<div className={clsx('loading-overlay__container', {
		'loading-overlay__container--bottom': bottom,
	})}>
		{children}
	</div>
);

export const LoadingOverlayDetailText = ({ children }) => (
	<p className="loading-overlay__detail-text">
		{children}
	</p>
);

export const LoadingOverlayFlexColumn = ({ children }) => (
	<div className="loading-overlay__flex-column">
		{children}
	</div>
);

export const LoadingOverlayTitle = ({ children }) => (
	<h1 className="loading-overlay__title">
		{children}
	</h1>	
);

export const LoadingOverlayDescription = ({ quotes, randomIndex }) => {
	const [quoteIndex, setQuoteIndex] = useState(randomIndex || 0);
	const { t } = useTranslation();
	const description = useRef();

	const textAnimation = index => {
		gsap.to(description.current, { duration: .25, opacity: 0, onComplete: () => {
			setQuoteIndex(index);
			gsap.to(description.current, { duration: .25, opacity: 1 });
		}});
	};

	useEffect(() => {
		const textTimer = setTimeout(() => {
			if (quoteIndex === quotes.length - 1) {
				textAnimation(0);
			} else {
				textAnimation(quoteIndex + 1);
			}
		}, 5000);

		return () => clearTimeout(textTimer);
	}, [quoteIndex]);

	return (
		<p className="loading-overlay__description" ref={description}>
			{t(`loadingScreen.quotes.${quoteIndex}.text`)}
		</p>
	);
};

export const LoadingOverlayLogo = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="88" height="55" viewBox="0 0 220 140" fill="#fff" className="loading-overlay__logo-viewbox">
		<g>
			<path d="M65, 0 L0, 109 H54.5 L79, 68 V0 M109.25, 0 L174.5, 109 H120 L95.25, 68 V0" className="loading-overlay__logo-path" />
		</g>
	</svg>
);

export const LoadingOverlayImage = ({ children, masking, visible, invisible }) => (
	<div className={clsx('loading-overlay__image', {
		'loading-overlay__image--masking': masking,
		'loading-overlay__image--visible': visible,
		'loading-overlay__image--invisible': invisible,
	})}>
		{children}
	</div>
);