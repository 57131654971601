import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import gsap from 'gsap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import { isSafari, isTouchDevice } from './../utils/helpers';
import GlobalContext from '../context/global-context';

const arrowsTimeline = gsap.timeline({
	defaults: {
		duration: .25,
		ease: 'power4.inOut',
	},
});

const CursorMask = ({
	showMaskingFullscreen,
	showDoubleArrows,
	setShowCursorMask,
	backgroundImage,
}) => {
	const blockElement = useRef();
	const cursorShape = useRef();
	const cursorOutline = useRef();
	const cursorOutlinePath = useRef();
	const cursorOutlineText = useRef();
	const maskingImageRef = useRef();
	const { t } = useTranslation();
	const [ cursorTextHidden, setCursorTextHidden ] = useState(false);
	const globalContext = useContext(GlobalContext);

	const animateBlockElement = () => {
		gsap.to(blockElement.current, {
			opacity: 1,
			duration: 1,
			delay: .4,
		});
	};

	const setInitialCursorPosition = () => {
		
		const canvasHalfWidth = globalContext.windowWidth / 2;
		const canvasHalfHeight = globalContext.windowHeight / 2;

		gsap.set([cursorShape.current, cursorOutline.current], {
			x: isSafari() && !isTouchDevice() ? -cursorOutlinePath.current.getBoundingClientRect().width : canvasHalfWidth - cursorOutlinePath.current.getBoundingClientRect().width / 2,
			y: isSafari() && !isTouchDevice() ? -cursorOutlinePath.current.getBoundingClientRect().height : canvasHalfHeight - cursorOutlinePath.current.getBoundingClientRect().height * 1.5,
		});
		gsap.set([cursorOutlineText.current], {
			x: cursorOutlinePath.current.getBoundingClientRect().width / 2 + (isTouchDevice() ? 6 : 3),
		});
	};

	const createArrowsAnimation = () => {
		gsap.to(cursorOutlineText.current, { opacity: 0, duration: .25 });

		arrowsTimeline
			.fromTo([cursorShape.current, cursorOutlinePath.current],
				{ morphSVG: 'm63.576 55.1-12.34-20.507V.5h6.9l32.862 54.6Zm-60.332 0L36.102.5h6.9v34.1l-12.34 20.5Z' }, // Logo shape
				{ morphSVG: 'M6.45,51.73,47,28.36,6.45,5Z M49.45,5V51.73L90,28.36Z' }, // Double arrows shape
				0);
			
		if (isSafari()) {
			arrowsTimeline
				.fromTo(maskingImageRef.current,
					{ opacity: 1 },
					{ opacity: 0 },
					0);
		}
		arrowsTimeline.pause();
	};

	const triggerArrowsAnimation = () => {
		if (showDoubleArrows) {
			return arrowsTimeline.play();
		}
		return arrowsTimeline.reverse();
	};

	const addMouseMoveListener = () => {
		if (showMaskingFullscreen) {
			return window.removeEventListener('mousemove', moveMaskIcon); 
		}
		setInitialCursorPosition();
		return window.addEventListener('mousemove', moveMaskIcon); 
	};

	const triggerFullscreenAnimation = () => {
		if (showMaskingFullscreen) {
			setCursorTextHidden(true);
			gsap.to(cursorOutlinePath.current, { opacity: 0, duration: .25 });
			gsap.to([cursorShape.current ], {
				x: 0,
				y: 0,
				morphSVG: `
					M${globalContext.windowWidth / 2},${globalContext.windowHeight}
					H${globalContext.windowWidth}
					L${globalContext.windowWidth},0
					H${globalContext.windowWidth / 2}
					V${globalContext.windowHeight}Z
					M0,${globalContext.windowHeight}
					H${globalContext.windowWidth / 2}
					V0
					H0
				`,
				ease: 'power4.inOut',
				duration: 1,
				onComplete: () => setShowCursorMask(false),
			});
		}
	};

	const moveMaskIcon = useCallback(event => {
		
		gsap.to([cursorShape.current, cursorOutline.current], {
			x: parseInt(event.clientX - (cursorOutline.current.getBBox().height / 2)),
			y: parseInt(event.clientY - (cursorOutline.current.getBBox().width / 2)),
			duration: .3,
			ease: 'power3.out',
		});
		const xPosRelativeToWindow = event.clientX / globalContext.windowWidth;
		const yPosRelativeToWindow = event.clientY / globalContext.windowHeight;

		if(xPosRelativeToWindow > .2 && xPosRelativeToWindow < .8 && yPosRelativeToWindow > .2) {
			setCursorTextHidden(false);
		} else {
			setCursorTextHidden(true);
		}

	}, []);

	useEffect(() => {
		gsap.registerPlugin(MorphSVGPlugin);
		if(!isTouchDevice()) { createArrowsAnimation(); }
		animateBlockElement();
		return () => window.removeEventListener('mousemove', moveMaskIcon);
	}, []);

	useEffect(() => {
		triggerArrowsAnimation();
	}, [showDoubleArrows]);

	useEffect(() => {
		if(!isTouchDevice()) {
			addMouseMoveListener();
		} else {
			setInitialCursorPosition();
		}

		triggerFullscreenAnimation();
	}, [showMaskingFullscreen, globalContext.windowWidth]);

	useEffect(() => {
		gsap.to(cursorOutlineText.current, { opacity: cursorTextHidden ? 0 : 1, duration: .25 });
	}, [cursorTextHidden]);

	return (
		<div ref={blockElement} className="cursor-mask cursor-none">
			<svg className="cursor-mask__svg" xmlns="http://www.w3.org/2000/svg" width={globalContext.windowWidth} height={globalContext.windowHeight} viewBox={`0 0 ${globalContext.windowWidth} ${globalContext.windowHeight}`}>
				<clipPath id="mask-icon">
					<path
						ref={cursorShape}
						className="cursor-mask__icon-path"
						d={isTouchDevice() ?
							'M102.91,119.28h42.28L93.72,33.77H83.57V87.15Zm-95,0H50.19L69.52,87.16V33.77H59.37Z'
							: 'm63.576 55.1-12.34-20.507V.5h6.9l32.862 54.6Zm-60.332 0L36.102.5h6.9v34.1l-12.34 20.5Z'}
					/>
				</clipPath>
				<g ref={cursorOutline}>
					<path
						ref={cursorOutlinePath}
						stroke="#fff"
						strokeWidth={isTouchDevice() ? '2' : '1'}
						fill="none"
						className="cursor-mask__icon-path"
						d={isTouchDevice() ?
							'M102.91,119.28h42.28L93.72,33.77H83.57V87.15Zm-95,0H50.19L69.52,87.16V33.77H59.37Z'
							: 'm63.576 55.1-12.34-20.507V.5h6.9l32.862 54.6Zm-60.332 0L36.102.5h6.9v34.1l-12.34 20.5Z'}
					/>
					{isTouchDevice() ?
						<text ref={cursorOutlineText} xmlns="http://www.w3.org/2000/svg" id="H1" transform='translate(0 140)' fill="#fff" fontSize="16" fontFamily="Avenir-Heavy, Avenir" fontWeight="800">
							<tspan textAnchor="middle" x="0" y="9">{t('global.frontpageCursorFirstLine')}</tspan>
							<tspan textAnchor="middle" x="0" y="30">{t('global.frontpageCursorSecondLine')}</tspan>
						</text>
						:
						<text ref={cursorOutlineText} xmlns="http://www.w3.org/2000/svg" id="H1" transform='translate(0 64.5)' fill="#fff" fontSize="9" fontFamily="Avenir-Heavy, Avenir" fontWeight="800">
							<tspan textAnchor="middle" x="0" y="9">{t('global.frontpageCursorFirstLine')}</tspan>
							<tspan textAnchor="middle" x="0" y="21">{t('global.frontpageCursorSecondLine')}</tspan>
						</text>
					}
				</g>
			</svg>
			{isSafari() && (
				<figure className="cursor-mask__figure">
					<img
						className="cursor-mask__image"
						src={backgroundImage}
						alt="Background"
						ref={maskingImageRef}
					/>
				</figure>
			)}
		</div>
	);
};

export default CursorMask;