import React from 'react';
import clsx from 'clsx';

export const FooterBlock = ({ children, invisible }) => (
	<footer className={clsx('footer', {
		'footer--invisible': invisible,
	})}>
		{children}
	</footer>
);

export const FooterContent = ({ content }) => (
	<div dangerouslySetInnerHTML={{ __html: content }} className="footer__content" />
);

export const FooterLink = ({ url, children, highlight, self }) => (
	<a
		href={url}
		rel="noopener noreferrer"
		target={self ? '' : '_blank'}
		className={clsx('footer__link', {
			'footer__link--highlight': highlight,
		})}
	>
		{children}
	</a>
);